.basic{
  padding: 22px 40px 5px;

  .ant-transfer-list{
    width: 176px;
    height: 232px;
  }
  .ant-checkbox-indeterminate .ant-checkbox-inner::after{
    height: 2px;
  }

  .secret{
    >p{
      width: 90px;
      height: 90px;
      background-color: #ffffff;
      border-radius: 10px;
      border: solid 1px #e1e7ef;
      display: inline-block;
      text-align: center;
      line-height: 90px;

      img{
        width: 68px;
        height: 68px;
      }
    }
    span{
      color: #0084ff;
      &:hover{
        cursor: pointer;
      }
    }
  }

    .before{
        display: inline-block;
        margin-right: 4px;
        color: #ff4d4f ;
        font-size: 14px;
        font-family: SimSun, sans-serif;
    }
}

.table{
  .secretKeyImg {
    width: 48px;
    height: 48px;
    border-radius: 5px;
    border: solid 1px var(--border-color);
  }
  .showSecretkey-btn {
    cursor: pointer;
    padding-left: 0.5rem;
    color: var(--font-color-caption);
  }
  .iconHide {
    display: inline-block;
    width: 14px;
    height: 14px;
    vertical-align: middle;
  }
}

.btn2{
  .ant-btn{
    width: 110px;
    &:first-child{
      margin-right: 10px;
    }
  }
}

.context{
  padding: 24px 24px 22px;

  >p{
    color: #333;
    margin-bottom: 10px;
  }

  .filelist {
    margin-top: 6px;
  }
}

.operate{
  .ant-dropdown-trigger{
    color: #0084ff;
    cursor: pointer;
  }
} 
.validity_dropdown{
  .ant-dropdown-trigger{
    color:  #bfc6d0;
    cursor: not-allowed
  }
}
.is_freeze {
  display: flex;
  align-items: center;

  .block {
    display: block;
    width: 8px;
    height: 8px;
    background-color: pink;
    margin-right: 8px;
    border-radius: 50%;
  }

  .freeze {
    background-color: #C8C8C8;
  }

  .allow {
    background-color: #4BC819;
  }

  .no_allow {
    background-color: #FAAD14;
  }
}
.freeze_btn{
  color: #333 !important;
}
.modify{
  .ant-dropdown-menu{
    width: 87px !important;
    text-align: center;
  }
}

.mpasseord{
  padding: 21px 40px;
}