.ant-layout {
  min-height: 100vh;
  background-color: var(--grey-color);
}

.ant-layout-header,
.ant-layout-footer {
  background-color: white;
}
.ant-layout-header {
  position: fixed;
  width: 100%;
  box-shadow: 0px 1px 2px 0px rgba(0, 21, 41, 0.12);
  z-index: 100;
}

.ant-layout-content {
  // new+
  position: relative;
  // end
  display: flex;
  align-items: flex-start;
  padding-top: var(--header-height);
}

.ant-layout-header {
  height: var(--header-height);
}
.ant-modal{
  padding-bottom: 0;
}

.ant-modal-content {
  box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.2);
  border-radius: 10px;
}

.ant-btn {
  border-radius: 4px;
  text-shadow: none;
  box-shadow: none;
}

table {
  display: table;
}

table th,
table td {
  border: unset;
}

table tr:nth-child(2n) {
  background-color: unset;
}

.ant-table thead > tr > th {
  font-weight: var(--font-bold);
}

.ant-pagination-options-size-changer {
  width: 100px !important;
}
.ant-btn-primary[disabled],
.ant-btn[disabled] {
  background-color: #edf0f6;
  color: #bfc6d0;
  border-color: #e1e7ef;
  &:hover {
    background-color: #edf0f6;
    color: #bfc6d0;
  }
}
.ant-btn-link[disabled]{
  background: none!important;
  color: #bfc6d0;
  border: none!important;
  &:hover {
    background: none!important;
    color: #bfc6d0;
  }
}
.ant-layout-header {
  padding: 0;
}

.ant-btn-primary:focus {
  color: #fff;
  background-color: #0084ff;
  border-color: #0084ff;
}

.ant-table {
  border-radius: 10px;
  overflow: hidden;
  thead tr th {
    border-bottom: none;
  }
}

.ant-back-top-content {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  background-color: white !important;
  box-shadow: 0px 0px 4px 0px rgba(30, 96, 249, 0.1);
}
.ant-back-top-content:hover {
  box-shadow: 0px 0px 15px 0px rgba(30, 96, 249, 0.1);
}
.ant-back-top-icon {
  background-image: url('/image/back2Top.png') !important;
  background-size: 130% 130% !important;
  width: 100% !important;
  height: 100% !important;
  margin: unset !important;
  background-position: center;
}
.ant-back-top {
  bottom: 73px;
  right: 63px !important;
}
.ant-btn-primary {
  border-color: transparent;
}

.ant-form-item-has-error
  .ant-select-single.ant-select-lg:not(.ant-select-customize-input)
  .ant-select-selector {
  height: 38px;
}

.ant-checkbox-indeterminate .ant-checkbox-inner::after{
  height: 2px;
}
.ant-empty-description{
  color: #999999 !important;
}
.ant-form-item-explain, .ant-form-item-extra{
  padding-top: 4px;
  padding-bottom: 12px;
}
.ant-radio-inner::after {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 8px;
  height: 8px;
  transform: translate(-50%,-50%) scale(1) !important;
}