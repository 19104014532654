#follow {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  align-self: stretch;
  flex-direction: column;
  background-color: white;
  margin-bottom: 80px;
  h1 {
    height: 43px;
    font-size: 28px;
    font-weight: var(--font-bold);
    color: var(--font-color-title);
    margin-top: 60px;
    display: flex;
    justify-content: space-between;
    .btn {
      display: flex;
      align-items: center;
      .tip {
        width: 20px;
        height: 20px;
        margin-right: 12px;
      }
    }
  }
  .content {
    margin-top: 42px;
    .search {
      display: flex;
      justify-content: flex-end;
      margin-bottom: 22px;
    }
    .name {
        color: var(--font-color-body);
        cursor: pointer;
        &:hover {
          color: var(--primary-color);
        }
        img {
          width: 48px;
          height: 48px;
          border-radius: 10px;
          border: solid 1px var(--border-color);
          margin-right: 12px;
        }
      }
    .pagination {
      display: flex;
      justify-content: flex-end;
      margin-top: 10px;
    }
    .nodata {
        margin: 100px 0 20px;
        text-align: center;
        font-size: 14px;
        img {
            width: 123px;
        }
        .tips {
            color: var(--font-color-caption);
            padding: 25px 0 30px;
        }
    }
  }
}
