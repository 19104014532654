#player{
    margin: -97px auto 0;
}

.tcplayer{
    width: 84vw;
    height: 100vh;
}
// .vjs-menu-button.vjs-menu-button-popup.vjs-control.vjs-button.tcp-video-quality-switcher{
//     display: none;
// }